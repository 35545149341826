<template>
  <div class="ManagerTasksDashboard">

    <CRow>
      <CCol>
        <h1> Aide - Rôle des utilisateurs </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <aideRoleComponent />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import aideRoleComponent from '@/components/aideComponents/aideRoleComponent'

export default {
  name: 'ManagerTasksDashboard',
  components: {
    aideRoleComponent,
  },
}
</script>
